import { useCallback, useState } from "react";
import { AITextOption } from "../../Pages/AI/utils";

export interface UseAIChatSettings {
  model: AITextOption;
  recency: AIChatRecency;
  contextAmount: number;
  setSetting: (setting: AIChatSettings, value: any) => void;
  setSettings: (settings: AIChatSettings[], values: any[]) => void;
}

type AIChatRecency = "any" | "month" | "week" | "day" | "hour";

type AIChatSettings = "model" | "recency" | "contextAmount";

export const defaultAIChatSettings: UseAIChatSettings = {
  model: "gpt-4o-mini",
  recency: "any",
  contextAmount: 5,
  setSetting: () => {},
  setSettings: () => {},
};

const useAIChatSettings: () => UseAIChatSettings = () => {
  const [model, setModel] = useState<AITextOption>("gpt-4o-mini");
  const [recency, setRecency] = useState<AIChatRecency>("any");
  const [contextAmount, setContextAmount] = useState<number>(5);

  const setSetting = useCallback(
    (setting: AIChatSettings, value: any) => {
      switch (setting) {
        case "model":
          setModel(value);
          break;
        case "recency":
          setRecency(value);
          break;
        case "contextAmount":
          setContextAmount(value);
          break;
        default:
          break;
      }
    },
    [setModel, setRecency]
  );

  const setSettings = useCallback(
    (settings: AIChatSettings[], values: any[]) => {
      settings.forEach((setting, i) => {
        setSetting(setting, values[i]);
      });
    },
    [setSetting]
  );

  return { model, recency, contextAmount, setSetting, setSettings };
};

export default useAIChatSettings;
