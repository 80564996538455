import { ReactComponent as PaperClip } from "../../../SVGs/paperclip-solid.svg";
import { ReactComponent as Arrow } from "../../../SVGs/arrow-up-solid.svg";
import { ReactComponent as XIcon } from "../../../SVGs/x-solid.svg";
import { ReactComponent as Clock } from "../../../SVGs/clock-rotate-left-solid.svg";
import { ReactComponent as GearIcon } from "../../../SVGs/gear-solid.svg";
import { useContext, useState } from "react";
import { AIChatContext } from "../../../Context/AIGen/AIChatContext";
import { AIOptions, AITextOption, recencyOptions } from "../../AI/utils";
import Modal from "../../../Components/Modal/Modal";
import FileUpload from "../../../Components/Input/FileUpload/FileUpload";
import useContextMenu from "../../../Hooks/Input/useContextMenu";
import { AIMessage } from "../../../Types/chat";
import AIChatSettingsModal from "../Settings/AIChatSettingsModal";

const AIChatInput: React.FC = () => {
  const { model, setSetting, recency, sendMessage, chatLoading } =
    useContext(AIChatContext);

  const { ContextMenu, onContextMenu } = useContextMenu();
  const [prompt, setPrompt] = useState<string>("");
  const [attachments, setAttachments] = useState<string[]>([]);
  const [modalState, setModalState] = useState<number>(-1);
  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSetting("model", e.target.value as AITextOption);
  };

  const handleRecencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSetting("recency", e.target.value as string);
  };

  const handleSend = async () => {
    if (!prompt) return;
    if (chatLoading) return;
    sendMessage([
      {
        content: prompt,
        type: "text",
        from: "user",
        created_at: new Date(),
      },
      ...attachments.map(
        (url) =>
          ({
            content: url,
            type: "image",
            from: "user",
            created_at: new Date(),
          } as AIMessage)
      ),
    ]);

    setPrompt("");
    setAttachments([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      handleSend();
    }
  };

  const onFileSuccess = (url: string) => {
    setAttachments((prev) => [...prev, url]);
  };

  return (
    <>
      <ContextMenu />
      <Modal
        onClose={() => setModalState(-1)}
        title={`Upload`}
        opened={modalState >= 0}
      >
        <div className="editor-upload-modal-container">
          <FileUpload
            onSuccess={onFileSuccess}
            subfile={"ai-chat"}
            id={`image-uploader`}
            uploadTo={`/upload/image`}
          />
        </div>
      </Modal>
      <Modal opened={settingsOpened} onClose={() => setSettingsOpened(false)}>
        <AIChatSettingsModal />
      </Modal>
      <div className="ai-chat-footer">
        <div className="ai-chat-container">
          <div className="ai-chat-input-container">
            <div className="ai-chat-attachment-container">
              {attachments.map((attachment, index) => (
                <div key={index} className="ai-chat-attachment">
                  <img
                    className="ai-chat-attachment-image"
                    src={attachment}
                    alt="attachment"
                  />
                  <button
                    onClick={() =>
                      setAttachments((prev) =>
                        prev.filter((_, i) => i !== index)
                      )
                    }
                  >
                    <XIcon
                      width={"12px"}
                      height={"12px"}
                      fill={"var(--base-text)"}
                    />
                  </button>
                </div>
              ))}
            </div>
            <textarea
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
              onKeyDown={handleKeyDown}
              onSubmit={(e) => console.log("found keypress on box")}
              placeholder="Message Chat"
              className="ai-chat-text-area"
            ></textarea>
          </div>
          <div className="ai-chat-bottom-container">
            <div className="ai-chat-options-container">
              <GearIcon
                onClick={(e: React.MouseEvent) => setSettingsOpened(true)}
                fill="var(--accent-text)"
                width={"16px"}
                height={"16px"}
              />
              <PaperClip
                onClick={(e: React.MouseEvent) => {
                  onContextMenu(
                    e,
                    [
                      {
                        element: "Image",
                        onClick: () => {
                          setModalState(0);
                        },
                      },
                    ],
                    { vertical: "bottom" }
                  );
                }}
                fill="var(--accent-text)"
                style={{ transform: "rotate(45deg) scale(-1,1)" }}
                width={"16px"}
                height={"16px"}
              />
              {model.includes("sonar") && (
                <label htmlFor="recency" className="ai-chat-recency-label">
                  <Clock
                    fill="var(--accent-text)"
                    width={"16px"}
                    height={"16px"}
                  />
                  <select
                    id="recency"
                    className="ai-chat-select-model left"
                    value={recency}
                    onChange={handleRecencyChange}
                  >
                    {recencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <div className="ai-chat-send-container">
              <select
                className="ai-chat-select-model"
                value={model}
                onChange={handleModelChange}
              >
                {AIOptions.text.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <button onClick={handleSend} className="ai-chat-send-button">
                <Arrow
                  fill="var(--secondary-text)"
                  width="16px"
                  height="16px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIChatInput;
