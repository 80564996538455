import { useEffect, useState } from "react";

export interface UseImageLoaderProps {
  images: ImageLoaderImage[];
}

export interface UseImageLoader {
  loadedImages: ImageLoaderImage[];
}

export interface ImageLoaderImage {
  src: string;
  name?: string;
  image?: HTMLImageElement;
}

const blackImage = () => {
  const blankImage = new Image();
  blankImage.width = 1;
  blankImage.height = 1;
  blankImage.src =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
  return blankImage;
};

const useImageLoader: (props: UseImageLoaderProps) => UseImageLoader = ({
  images,
}) => {
  const [loadedImages, setLoadedImages] = useState<ImageLoaderImage[]>([]);

  async function loadImageURL(url: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const elem = new Image();
      const id = setTimeout(() => {
        reject(new Error("Failed to load image"));
      }, 4000);
      elem.crossOrigin = "Anonymous";
      elem.onload = () => {
        clearTimeout(id);
        resolve(elem);
      };
      elem.onerror = () => {
        clearTimeout(id);
        reject(new Error("Failed to load image"));
      };
      elem.src = url + "?cb=" + Date.now();
    });
  }

  const loadImages = async () => {
    const imagePromises = images.map(async (img) => {
      try {
        const element = await loadImageURL(img.src);
        element.crossOrigin = "anonymous";
        return {
          image: element,
          src: img.src,
          name: img?.name,
        };
      } catch (e) {
        console.error(`Failed to load image: ${img.src}`, e);
        return {
          image: blackImage(),
          src: img.src,
          name: img?.name,
        };
      }
    });
    const newLoadedImages = await Promise.all(imagePromises);
    setLoadedImages(newLoadedImages);
  };

  useEffect(() => {
    setLoadedImages([]);
    loadImages();
  }, [images]);

  return { loadedImages };
};

export default useImageLoader;
