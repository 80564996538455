import { useContext, useMemo } from "react";
import { DataContext } from "../../Context/DataContext";

const usePermissionsNav = () => {
  const { permissions } = useContext(DataContext);

  const links = useMemo(() => {
    const links = [];
    if (permissions.post_creating) {
      links.push({ link: "/admin/projects", text: "Admin Projects" });
    }
    if (permissions.t_rights) {
      links.push({ link: "/unlocked_thoughts", text: "Thoughts" });
    }
    if (permissions.st_rights && permissions.j_rights && permissions.t_rights) {
      links.push({ link: "/entries", text: "Entries" });
    }
    if (permissions.j_rights) {
      links.push({ link: "/journal_entry", text: "Journal Entry" });
    }
    if (permissions.aic && permissions.aig) {
      links.push({ link: "/ai", text: "Image Gen" });
    }
    if (permissions.aic && permissions.aig) {
      links.push({ link: "/chat", text: "Chat" });
    }
    return links;
  }, [permissions]);

  return { links };
};

export default usePermissionsNav;
