import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { AIChatContext } from "../../../Context/AIGen/AIChatContext";
import ChatCard from "./ChatCard";
import Button from "../../../Components/Button/Button";

const CurrentAIChat: React.FC = () => {
  const { conv, chatLoading, loadConv, morePages, page } =
    useContext(AIChatContext);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();
  const prevHeightRef = useRef<number>(0);
  const scrollPosRef = useRef<number>(0);

  useLayoutEffect(() => {
    if (page === 0) {
      scrollTimeoutRef.current = setTimeout(() => {
        window.scrollTo(0, 10000);
      }, 10);
    } else {
      const diff =
        document.documentElement.scrollHeight - prevHeightRef.current;
      window.scrollTo(0, diff + scrollPosRef.current);
      scrollTimeoutRef.current = setTimeout(() => {
        const diff =
          document.documentElement.scrollHeight - prevHeightRef.current;
        window.scrollTo(0, diff + scrollPosRef.current);
      }, 1);
    }
    return () => {
      clearTimeout(scrollTimeoutRef.current);
    };
  }, [conv, page]);

  const loadMore = () => {
    prevHeightRef.current = document.documentElement.scrollHeight;
    scrollPosRef.current = window.scrollY;
    loadConv();
  };

  return (
    <div className="ai-chat-flex-center">
      <div className="ai-chat-conversation-container">
        {morePages && conv.length > 0 && (
          <div className="flex-center padding-bottom-20 margin-top-20">
            <Button onClick={loadMore}>Load More</Button>
          </div>
        )}
        {conv.map((msg, i) => (
          <ChatCard key={"chat-card-" + i} msg={msg} />
        ))}
        {chatLoading && <div>Loading...</div>}
      </div>
    </div>
  );
};

export default CurrentAIChat;
