export type AITextOption = "gpt-4o" | "gpt-4o-mini" | "gpt-3.5-turbo" | "gpt-4";

const AIOptions = {
  text: [
    { text: "GPT-4o", value: "gpt-4o" },
    { text: "GPT-4o-mini", value: "gpt-4o-mini" },
    { text: "Sonar", value: "sonar" },
    { text: "Sonar Pro", value: "sonar-pro" },
    // { text: "GPT-3.5-Turbo", value: "gpt-3.5-turbo" },
    // { text: "GPT-4", value: "gpt-4" },
  ],

  image: [
    { text: "Flux-Pro-1.1", value: "flux-pro-1.1" },
    { text: "SD-Ultra", value: "sd-ultra" },
    { text: "Flux-pro", value: "flux-pro" },
    { text: "Flux-dev", value: "flux-dev" },
    { text: "Dalle-2", value: "dalle-2" },
    { text: "Dalle-3", value: "dalle-3" },
    { text: "sd3.5-large", value: "sd3.5-large" },
    { text: "sd3.5-large-turbo", value: "sd3.5-large-turbo" },
    { text: "sd3.5-medium", value: "sd3.5-medium" },
    { text: "sd3-large", value: "sd3-large" },
    { text: "sd3-large-turbo", value: "sd3-large-turbo" },
    { text: "sd3-medium", value: "sd3-medium" },
  ],
};

const AIRefTypes = [
  { text: "Image Reference", value: "ref" },
  { text: "Style Reference", value: "style" },
];

const recencyOptions = [
  { text: "Any", value: "any" },
  { text: "Hour", value: "hour" },
  { text: "Day", value: "day" },
  { text: "Week", value: "week" },
  { text: "Month", value: "month" },
];

const contextAmountOptions = [
  { text: "none", value: 0 },
  { text: "low", value: 2 },
  { text: "medium", value: 5 },
  { text: "high", value: 10 },
];

export { AIOptions, AIRefTypes, recencyOptions, contextAmountOptions };
