import { useContext } from "react";
import { AIChatContext } from "../../../Context/AIGen/AIChatContext";
import { contextAmountOptions } from "../../AI/utils";

const AIChatSettingsModal: React.FC = () => {
  const { contextAmount, setSetting } = useContext(AIChatContext);
  const handleContextAmount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSetting("contextAmount", Number(e.target.value));
  };

  return (
    <div className="contact-modal-container">
      <span>Context Amount</span>
      <select
        className="ai-chat-select-model center"
        value={contextAmount}
        onChange={handleContextAmount}
      >
        {contextAmountOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AIChatSettingsModal;
