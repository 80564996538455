import { createContext, useMemo, useState } from "react";
import { AITextOption } from "../../Pages/AI/utils";
import useAIConversation, {
  UseAIConversationHook,
} from "../../Hooks/Chat/useAIConversation";
import useAIChatSettings, {
  defaultAIChatSettings,
  UseAIChatSettings,
} from "../../Hooks/Chat/useAIChatSettings";
const baseURL = process.env.REACT_APP_BASE_URL;

interface AIChatContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface baseAIProps {
  model: AITextOption;
  cMenuOpened: boolean;
  setCMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

type AIChatContextProps = baseAIProps &
  UseAIConversationHook &
  UseAIChatSettings;

export const AIChatContext = createContext<AIChatContextProps>({
  conv: [],
  sendMessage: () => {},
  loadConv: () => {},
  newConv: () => {},
  chatLoading: false,
  cMenuOpened: false,
  setCMenuOpened: () => {},
  clearConv: () => {},
  convID: undefined,
  morePages: false,
  page: 0,
  ...defaultAIChatSettings,
});

export const AIChatContextProvider: React.FC<AIChatContextProviderProps> = ({
  children,
}) => {
  const settings = useAIChatSettings();
  const [cMenuOpened, setCMenuOpened] = useState<boolean>(false);
  const conv = useAIConversation({ settings });
  const value: AIChatContextProps = useMemo(() => {
    return { ...settings, ...conv, cMenuOpened, setCMenuOpened };
  }, [
    settings.model,
    settings.recency,
    settings,
    conv.chatLoading,
    conv.conv,
    conv.loadConv,
    conv.newConv,
    conv.sendMessage,
    cMenuOpened,
    setCMenuOpened,
    conv.clearConv,
    conv.convID,
    conv.morePages,
    conv.page,
    conv,
  ]);

  return (
    <AIChatContext.Provider value={value}>{children}</AIChatContext.Provider>
  );
};
