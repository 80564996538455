import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactMouseEvent } from "../../Particles/hooks/useMouseCursor";

interface ContextMenuItem {
  element: JSX.Element | string;
  onClick?: () => Promise<void> | void;
}

interface UseContextMenu {
  ContextMenu: React.FC;
  onContextMenu: (
    e: React.MouseEvent,
    options: ContextMenuItem[],
    settings?: ContextMenuSettings
  ) => void;
}

interface ContextMenuSettings {
  vertical?: "top" | "bottom";
  horizontal?: "left" | "right";
}

const useContextMenu: () => UseContextMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [settings, setSettings] = useState<ContextMenuSettings>({
    vertical: "top",
    horizontal: "left",
  });
  const [options, setOptions] = useState<ContextMenuItem[]>([]);

  const onContextMenu = useCallback(
    (
      e: React.MouseEvent,
      options: ContextMenuItem[],
      settings?: ContextMenuSettings
    ) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
      setPosition({ x: e.clientX, y: e.clientY });
      setSettings((s) => ({ ...s, ...settings }));
      setOptions(options);
    },
    [open, settings]
  );

  const ContextMenu: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
          e.preventDefault();
          e.stopPropagation();
          setOpen(false);
        }
      };
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);

    const onSubContextMenu = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (!open) return null;

    return (
      <div
        ref={ref}
        onContextMenu={onSubContextMenu}
        className="context-menu-container"
        style={{
          top: position.y,
          left: position.x,
          transform: `translate(${
            settings.horizontal === "right" ? "-100%" : "0px"
          },${settings.vertical === "bottom" ? "-100%" : "0px"})`,
        }}
      >
        {options?.map((option, i) => {
          const click = async (e: ReactMouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            await option?.onClick?.();
            setOpen(false);
          };
          if (typeof option.element === "string") {
            return (
              <span
                key={option.element + "-" + i}
                onClick={click}
                className="context-menu-option"
              >
                {option.element}
              </span>
            );
          }
          return option.element;
        })}
      </div>
    );
  };

  return {
    ContextMenu,
    onContextMenu,
  };
};

export default useContextMenu;
