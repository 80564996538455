import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useRenderTool from "../../Hooks/useRenderTool";
import { RenderTool } from "../../Components/Rendering/types/types";
import { loadImageURL } from "../../Components/Rendering/utils/image";
import { ImageRTLayer } from "../../Components/Rendering/types/editor";
const baseURL = process.env.REACT_APP_BASE_URL;

interface AIGenContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface AIGenContextProps {
  route: string;
  rt: RenderTool | null;
  rtRef: (ref: HTMLCanvasElement | null) => void;
  refImage?: string;
  setRefImage: (url: string | undefined) => void;
  setImage: (url: string) => Promise<void>;
  stateTrigger: boolean;
  prompt: string;
  triggerState: () => void;
  setPrompt:
    | React.Dispatch<React.SetStateAction<string>>
    | ((str: string) => void);
  setRoute:
    | React.Dispatch<React.SetStateAction<string>>
    | ((str: string) => void);
}

export const AIGenContext = createContext<AIGenContextProps>({
  route: "image",
  rt: null,
  rtRef: () => {},
  setImage: (url: string) => new Promise(() => {}),
  setRefImage: (url: string | undefined) => {},
  prompt: "",
  stateTrigger: false,
  triggerState: () => {},
  setPrompt: () => {},
  setRoute: () => {},
});

export const AIGenContextProvider: React.FC<AIGenContextProviderProps> = ({
  children,
}) => {
  const { tool: rt, ref } = useRenderTool();
  const [prompt, setPrompt] = useState<string>("");
  const [refImage, sRefImage] = useState<string | undefined>(undefined);
  const [route, setRoute] = useState<string>("image");
  const [stateTrigger, setStateTrigger] = useState<boolean>(false);

  const triggerState = useMemo(() => {
    return () => {
      setStateTrigger((s) => !s);
    };
  }, [setStateTrigger]);

  const setRefImage = useCallback(
    (url: string | undefined) => {
      sRefImage(url);
    },
    [sRefImage]
  );

  const setRTImage = async (url: string) => {
    const res = await loadImageURL(url);

    // rt?.editor.clearAllLayers();
    rt?.editor.resize({ width: res.width, height: res.height });
    if (rt) {
      rt.camera.x = res.width / 2;
      rt.camera.y = res.height / 2;

      const indx = rt?.editor.layers.findIndex((l) => l.name === "ai-image");
      if (indx !== -1) {
        if (rt.editor.layers[indx] && rt.editor.layers[indx].type === "image") {
          const layer = rt.editor.layers[indx] as ImageRTLayer;
          layer.image = res;
          rt.editor.rerender = true;
        }
      } else {
        const num = await rt?.editor.addLayer({
          layer: { type: "image", name: "ai-image", image: res },
        });
        if (num !== 0) {
          const layer = rt.editor.layers[num] as ImageRTLayer;
          const layers = rt.editor.layers.filter((l) => l.name !== "ai-image");
          rt.editor.layers = [layer, ...layers];
          rt.editor.selectedLayer++;
        }
      }
      rt?.r.render();
    }
  };

  const value: AIGenContextProps = useMemo(() => {
    return {
      rt: rt,
      rtRef: ref,
      setImage: setRTImage,
      prompt,
      refImage,
      setRefImage,
      setPrompt: setPrompt,
      route,
      setRoute,
      stateTrigger,
      triggerState,
    };
  }, [
    rt,
    ref,
    setRTImage,
    prompt,
    setPrompt,
    route,
    setRoute,
    triggerState,
    refImage,
    setRefImage,
  ]);

  return (
    <AIGenContext.Provider value={value}>{children}</AIGenContext.Provider>
  );
};
