import { useCallback, useContext, useState } from "react";
import { AIMessage } from "../../Types/chat";
import { DataContext } from "../../Context/DataContext";
import { UseAIChatSettings } from "./useAIChatSettings";

interface UseAIConversationProps {
  settings: UseAIChatSettings;
}

export interface UseAIConversationHook {
  conv: AIMessage[];
  sendMessage: (msg: AIMessage[]) => void;
  loadConv: (id?: number) => void;
  newConv: () => void;
  clearConv: () => void;
  chatLoading: boolean;
  convID: number | undefined;
  morePages: boolean;
  page: number;
}

//Loads in resoucres for the intro section particle stuff
const useAIConversation: (
  props: UseAIConversationProps
) => UseAIConversationHook = ({ settings }) => {
  const [conv, setConv] = useState<AIMessage[]>([]);
  const [convID, setConvID] = useState<number | undefined>(undefined);
  const { fetcher } = useContext(DataContext);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [amount, setAmount] = useState<number>(10);
  const [morePages, setMorePages] = useState<boolean>(true);
  const [convLoading, setConvLoading] = useState<boolean>(false);

  const sendMessage = useCallback(
    async (msgs: AIMessage[]) => {
      // if (convID === undefined) {
      //   //create new conversation
      //   return;
      // }
      setConv((prev) => [...prev, ...msgs]);
      setChatLoading(true);
      //add on extra settings
      let extra: any = {};
      if (settings.model.includes("sonar") && settings.recency !== "any") {
        extra.search_recency_filter = settings.recency;
      }
      //if there were any extra settings pass them on, and make sure they are part of the extra field as thats where they should be passed
      if (Object.keys(extra).length > 0) extra = { extra: extra };

      //set up the history context window
      let history: any[] = [];
      if (settings.contextAmount !== 0) {
        history = conv.slice(-settings.contextAmount);
      }
      const res = await fetcher("/chat/message/" + (convID ? convID : -1), {
        method: "POST",
        body: {
          msgs: msgs,
          model: settings.model,
          history: history,
          ...extra,
        },
      });
      if (res?.data?.text) {
        if (!convID) setConvID(res.data?.id);

        // let content = processMarkdown(res.data.text, res.data);
        const extra = JSON.parse(JSON.stringify(res.data));
        delete extra.text;
        delete extra.id;
        setConv((prev) => [
          ...prev,
          {
            type: "text",
            from: "assistant",
            content: res.data.text,
            created_at: new Date(),
            ...extra,
          },
        ]);
      }
      setChatLoading(false);
      // setTimeout(() => {
      //   setConv((prev) => [
      //     ...prev,
      //     {
      //       from: "assistant",
      //       type: "text",
      //       content: "Hello, how can I help you?",
      //     },
      //   ]);
      //   setChatLoading(false);
      // }, 1000);s
    },
    [
      setConv,
      setChatLoading,
      settings,
      settings.model,
      settings.contextAmount,
      settings.recency,
      convID,
    ]
  );

  const clearConv = useCallback(() => {
    setPage(0);
    setConv([]);
    setConvID(undefined);
    setChatLoading(false);
    setConvLoading(false);
    setMorePages(false);
  }, [
    setConv,
    setPage,
    setConvID,
    setChatLoading,
    setConvLoading,
    setMorePages,
  ]);

  const loadConv = useCallback(
    async (id?: number) => {
      //set up variabless for loading more into the context
      let anotherPage = false;
      if (!id && id !== 0) {
        if (!morePages) return;
        if (convID === undefined || convID < 0) return;
        anotherPage = true;
        id = convID;
      }
      setConvLoading(true);
      const res = await fetcher(
        "/chat/conversation/" +
          id +
          `?page=${anotherPage ? page + 1 : 0}&amount=${amount}`
      );
      if (anotherPage) setPage(page + 1);
      else setPage(0);

      if (res?.data?.length >= 0) {
        if (res?.data?.length < amount) setMorePages(false);
        else setMorePages(true);
        //set data based off of the data returned
        if (anotherPage) setConv([...res?.data, ...conv]);
        else setConv(res?.data);

        setConvID(id);
        setConvLoading(false);
      }
      setConvLoading(false);
    },
    [
      setConv,
      setConvID,
      setConvLoading,
      page,
      convID,
      conv,
      setPage,
      amount,
      setMorePages,
      morePages,
    ]
  );

  const newConv = useCallback(() => {}, [setConv]);

  return {
    conv,
    chatLoading,
    sendMessage,
    loadConv,
    newConv,
    clearConv,
    convID,
    morePages,
    page,
  };
};

export default useAIConversation;
