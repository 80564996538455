import { Matrix } from "../utils/math/matrix";
import { RTEditor } from "./editor";
import { RTRenderer } from "./renderer";

export interface RTMouse {
  x: number;
  y: number;
  isMiddleDown: boolean;
  isRightDown: boolean;
  isLeftDown: boolean;
  lastX: number;
  lastY: number;
  xCameraSpace: number;
  yCameraSpace: number;
  lastXCameraSpace: number;
  lastYCameraSpace: number;
}

export interface RTTouchPoint {
  x: number;
  y: number;
}

export interface RTTouch {
  touches: RTTouchPoint[];
  lastTouches: RTTouchPoint[];
  touchesCameraSpace: RTTouchPoint[];
  lastTouchesCameraSpace: RTTouchPoint[];
  avgDis: number;
  lastAvgDis: number;
}

export interface RenderObject {
  x: number;
}

export interface RTListeners {
  mouseWheel: (e: WheelEvent) => void;
  mouseMove: (e: MouseEvent) => void;
  mouseDown: (e: MouseEvent) => void;
  mouseUp: (e: MouseEvent) => void;
  resize: (e?: Event) => void;
  touchMove: (e: TouchEvent) => void;
  touchStart: (e: TouchEvent) => void;
  touchEnd: (e: TouchEvent) => void;
  touchCancel: (e: TouchEvent) => void;
}

export interface RTOptions {
  dpi: number;
}

export interface RTCamera {
  x: number;
  y: number;
  zoom: number;
  matrix: Matrix;
}

export interface RenderTool {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  mouse: RTMouse;
  touch: RTTouch;
  camera: RTCamera;
  options: RTOptions;
  r: RTRenderer;
  editor: RTEditor;
  listeners: RTListeners;
}

export const defaulRTMouse: RTMouse = {
  x: 0,
  y: 0,
  isMiddleDown: false,
  isRightDown: false,
  isLeftDown: false,
  lastX: 0,
  lastY: 0,
  xCameraSpace: 0,
  yCameraSpace: 0,
  lastXCameraSpace: 0,
  lastYCameraSpace: 0,
};

export const defaultRTTouch: RTTouch = {
  touches: [],
  lastTouches: [],
  touchesCameraSpace: [],
  lastTouchesCameraSpace: [],
  avgDis: -1,
  lastAvgDis: -1,
};

export const defaultRTCamera: RTCamera = {
  x: 512,
  y: 512,
  zoom: 0.5,
  matrix: new Matrix(),
};

export const defaultRTOptions: RTOptions = {
  dpi: window?.devicePixelRatio || 1,
};

export const defaultRTListeners: RTListeners = {
  mouseWheel: (e: Event) => {},
  mouseMove: (e: MouseEvent) => {},
  mouseDown: (e: MouseEvent) => {},
  mouseUp: (e: MouseEvent) => {},
  resize: (e?: Event) => {},
  touchMove: (e: TouchEvent) => {},
  touchStart: (e: TouchEvent) => {},
  touchEnd: (e: TouchEvent) => {},
  touchCancel: (e: TouchEvent) => {},
};
