import { useCallback, useContext } from "react";
import { ChatConversation } from "../../../Resources/Chat/Conversations";
import { AIChatContext } from "../../../Context/AIGen/AIChatContext";
import { ReactComponent as EllipsisIcon } from "../../../SVGs/ellipsis-solid.svg";
import useContextMenu from "../../../Hooks/Input/useContextMenu";
import { ReactMouseEvent } from "../../../Particles/hooks/useMouseCursor";

interface ConversationCardProps {
  conversation: ChatConversation;
  onDelete: (id: number) => void;
}

const ConversationCard: React.FC<ConversationCardProps> = ({
  conversation,
  onDelete,
}) => {
  const { loadConv, convID } = useContext(AIChatContext);

  const { ContextMenu, onContextMenu } = useContextMenu();

  const handleClick = useCallback(() => {
    loadConv(conversation.id);
  }, [loadConv, conversation]);

  return (
    <div
      onClick={handleClick}
      className={`ai-chat-cm-card ${
        convID === conversation.id ? "active" : ""
      }`}
    >
      <ContextMenu />
      <span>{conversation.title}</span>
      <EllipsisIcon
        onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          onContextMenu(
            e,
            [
              {
                element: "Delete",
                onClick: () => {
                  onDelete(conversation.id);
                },
              },
            ],
            { horizontal: "right" }
          );
        }}
        className="ai-chat-cm-ellipsis"
        width={16}
        height={16}
      />
    </div>
  );
};

export default ConversationCard;
