import "./chat.css";
import AIChatInput from "./Content/AIChatInput";
import ConversationManager from "./Content/ConversationManager";
import CurrentAIChat from "./Content/CurrentAIChat";

const ChatPage: React.FC = () => {
  return (
    <>
      <CurrentAIChat />
      <AIChatInput />
      <ConversationManager />
    </>
  );
};

export default ChatPage;
