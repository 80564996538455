import { useEffect, useMemo, useState } from "react";
import { AIMessage } from "../../../Types/chat";
import { ReactComponent as ChatIcon } from "../../../SVGs/robot-solid.svg";
import AudioChatCard from "./AudioChatCard";
import Markdown from "react-markdown";
import { processMarkdown } from "../../../Utils/ai/processMarkdown";

interface ChatCardProps {
  msg: AIMessage;
}

const ChatCard: React.FC<ChatCardProps> = ({ msg }) => {
  const cardClass = useMemo(() => {
    return msg.from === "assistant"
      ? "ai-chat-assistant-message"
      : `ai-chat-user-message ${msg.type === "image" ? "img" : ""}`;
  }, [msg]);

  const containerClass = useMemo(() => {
    return msg.from === "assistant"
      ? "ai-chat-assistant-message-container"
      : "ai-chat-user-message-container";
  }, [msg]);

  const [content, setContent] = useState<string | undefined>();

  useEffect(() => {
    setContent(processMarkdown(msg.content, msg));
  }, [msg.content]);

  return (
    <div className={containerClass}>
      {msg.from === "assistant" && (
        <div className="ai-chat-assistant-icon">
          <ChatIcon fill="var(--secondary-text)" width={"24px"} height="24px" />
        </div>
      )}
      <div className={cardClass}>
        {(msg.type === "text" || msg.type === "json") && (
          <>
            {msg.from === "assistant" && (
              <Markdown children={content}></Markdown>
            )}
            {msg.from === "user" && <p className="">{content}</p>}
          </>
        )}
        {msg.type === "image" && (
          <img className="w-75 border-radius-10" src={content} alt="img" />
        )}
        {msg.type === "audio" && <AudioChatCard msg={msg} />}
      </div>
    </div>
  );
};

export default ChatCard;
