import { RenderTool, RTTouchPoint } from "./types/types";
import { Matrix } from "./utils/math/matrix";
import { mouseBounds } from "./utils/mouseBounds";

export const initRTTouch = (rt: RenderTool) => {
  const onTouch = (e: TouchEvent) => {
    let foundTouch = false;
    //check if the touch event is on the canvas
    for (let i = 0; i < e.targetTouches.length; i++) {
      if (e.targetTouches[i].target === rt.canvas) {
        foundTouch = true;
        break;
      }
    }
    if (foundTouch) {
      //lock the scrolling of the page
      e.preventDefault();
      e.stopPropagation();
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      document.body.style.scrollBehavior = "none";

      //update the touch positions, and also get the camera space touch positions
      const rect = rt.canvas.getBoundingClientRect();
      //update the touch attributes
      rt.touch.lastTouchesCameraSpace = JSON.parse(
        JSON.stringify(rt.touch.touchesCameraSpace)
      );
      rt.touch.lastTouches = JSON.parse(JSON.stringify(rt.touch.touches));
      rt.touch.touches = [];

      for (let i = 0; i < e.touches.length; i++) {
        const x = e.touches[i].clientX - rect.left;
        const y = e.touches[i].clientY - rect.top;

        rt.touch.touches[i] = { x, y } as RTTouchPoint;

        const inverseTransform = rt.camera.matrix.invert();
        if (inverseTransform) {
          //get the touch position in camera space
          const cameraSpacePoint = inverseTransform.applyToPoint(
            //since the dpi might be higher or the canvas might be scaled we need to adjust the touch position
            x * (rt.canvas.width / rt.canvas.clientWidth),
            y * (rt.canvas.height / rt.canvas.clientHeight)
          );
          rt.touch.touchesCameraSpace[i] = {
            x: cameraSpacePoint.x,
            y: cameraSpacePoint.y,
          } as RTTouchPoint;
        }
      }

      //------------process and move according to touch event---------
      //move the mouse with the middle mouse button
      //------- MOVEMENT -------
      if (rt.touch.touches.length === 2) {
        if (rt.touch.lastTouches.length === 2) {
          //update the last touch dis
          rt.touch.lastAvgDis = rt.touch.avgDis;

          const disX = rt.touch.touches[0].x - rt.touch.touches[1].x;
          const disY = rt.touch.touches[0].y - rt.touch.touches[1].y;
          const dist = Math.sqrt(disX * disX + disY * disY);
          rt.touch.avgDis = dist;

          //we are attempting to move the camera position, based on the translation of the touch points
          if (
            Math.abs(rt.touch.lastAvgDis - rt.touch.avgDis) < 4 &&
            rt.touch.avgDis !== -1 &&
            rt.touch.lastAvgDis !== -1
          ) {
            //scroll lock everything
            e.preventDefault();
            e.stopPropagation();

            rt.camera.x -=
              (rt.touch.touches[0].x / rt.camera.zoom -
                rt.touch.lastTouches[0].x / rt.camera.zoom) *
              rt.options.dpi;
            rt.camera.y -=
              (rt.touch.touches[0].y / rt.camera.zoom -
                rt.touch.lastTouches[0].y / rt.camera.zoom) *
              rt.options.dpi;
          } else if (rt.touch.avgDis !== -1 && rt.touch.lastAvgDis !== -1) {
            //zoom in and out
            const delta = rt.touch.avgDis - rt.touch.lastAvgDis;
            rt.camera.zoom = Math.max(
              0.1,
              rt.camera.zoom + delta / (150 / rt.camera.zoom)
            );
          }
        }
        //limit the camera movement to bounds
        mouseBounds(rt);
        //rerender the view
        rt.r.render();
      } else {
        rt.touch.avgDis = -1;
        rt.touch.lastAvgDis = -1;
      }
      //------- TOOLS -------
      //tool/paint stuff, if you hold down three fingers it will erase
      if (rt.touch.touches.length === 1 || rt.touch.touches.length === 3) {
        if (!rt.editor.disabled) {
          rt.mouse.x = rt.touch.touches?.[2]?.x ?? rt.touch.touches[0].x;
          rt.mouse.y = rt.touch.touches?.[2]?.y ?? rt.touch.touches[0].y;
          //print out dpi
          rt.mouse.xCameraSpace =
            rt.touch.touchesCameraSpace?.[2]?.x ??
            rt.touch.touchesCameraSpace[0].x;
          rt.mouse.yCameraSpace =
            rt.touch.touchesCameraSpace?.[2]?.y ??
            rt.touch.touchesCameraSpace[0].y;
          if (
            (rt.touch.lastTouches.length >= 1 &&
              rt.touch.touches.length === 1) ||
            (rt.touch.lastTouches.length >= 3 && rt.touch.touches.length === 3)
          ) {
            rt.mouse.lastX =
              rt.touch.lastTouches?.[2]?.x ?? rt.touch.lastTouches[0].x;
            rt.mouse.lastY =
              rt.touch.lastTouches?.[2]?.y ?? rt.touch.lastTouches[0].y;
            rt.mouse.lastXCameraSpace =
              rt.touch.lastTouchesCameraSpace?.[2]?.x ??
              rt.touch.lastTouchesCameraSpace[0].x;
            rt.mouse.lastYCameraSpace =
              rt.touch.lastTouchesCameraSpace?.[2]?.y ??
              rt.touch.lastTouchesCameraSpace[0].y;
            rt.editor.toolFuncs[rt.editor.tool]?.(rt, e);
          }
        }
      }
    }
  };

  const onTouchEnd = (e: TouchEvent) => {
    //unlock the scrolling of the page

    document.body.style.overflow = "auto";
    document.body.style.touchAction = "auto";
    document.body.style.scrollBehavior = "auto";

    rt.touch.touches = [];
    rt.touch.lastTouches = [];
    rt.touch.touchesCameraSpace = [];
    rt.touch.lastTouchesCameraSpace = [];
    rt.touch.avgDis = -1;
    rt.touch.lastAvgDis = -1;
    //rerender the view
    rt.r.render();
  };

  rt.listeners.touchMove = onTouch;
  rt.listeners.touchStart = onTouch;
  rt.listeners.touchEnd = onTouchEnd;

  window.addEventListener("touchmove", onTouch);
  window.addEventListener("touchstart", onTouch, { passive: false });
  window.addEventListener("touchend", onTouchEnd);
};

export const cleanupRTTouch = (rt: RenderTool) => {
  window.removeEventListener("touchmove", rt.listeners.touchMove);
  window.removeEventListener("touchstart", rt.listeners.touchStart);
  window.removeEventListener("touchend", rt.listeners.touchEnd);
};
