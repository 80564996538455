import { useContext, useEffect, useRef } from "react";
import useChatConversations from "../../../Hooks/Chat/useChatConversations";
import ConversationCard from "./ConversationCard";
import { ReactComponent as SideBar } from "../../../SVGs/sidebar.svg";
import { ReactComponent as PlusSolid } from "../../../SVGs/plus-solid.svg";
import { AIChatContext } from "../../../Context/AIGen/AIChatContext";

const ConversationManager: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { conversations, getRecentConv, deleteConv } = useChatConversations();
  const { cMenuOpened, setCMenuOpened, clearConv } = useContext(AIChatContext);

  const handleClick = () => {
    setCMenuOpened((v) => !v);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement && ref.current) {
        if (!ref.current.contains(e.target)) {
          setCMenuOpened(false);
        }
      }
    };
    if (cMenuOpened) document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cMenuOpened, ref, setCMenuOpened]);

  useEffect(() => {
    getRecentConv();
  }, []);
  return (
    <>
      <div style={{ position: "fixed", top: "70px", right: "12px" }}>
        <SideBar
          fill="var(--accent-text)"
          width={24}
          height={24}
          onClick={handleClick}
        />
      </div>
      <div
        ref={ref}
        className={`ai-chat-conversation-manager-container  ${
          cMenuOpened ? "opened" : ""
        }`}
      >
        <div className="ai-chat-conversation-manager-sub-container">
          <div className="ai-chat-sidebar-header-container">
            <div className="ai-cm-header-title">Conversations</div>
            <div className="ai-cm-header-title">
              <PlusSolid width={20} height={20} onClick={() => clearConv()} />
              <SideBar width={20} height={20} onClick={handleClick} />
            </div>
          </div>
          {conversations.map((conv, i) => (
            <ConversationCard
              onDelete={deleteConv}
              key={`conversation-item-${i}`}
              conversation={conv}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ConversationManager;
