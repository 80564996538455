import { useCallback, useContext, useState } from "react";
import { ChatConversation } from "../../Resources/Chat/Conversations";
import { DataContext } from "../../Context/DataContext";
import { AIChatContext } from "../../Context/AIGen/AIChatContext";

interface UseChatConversations {
  conversations: ChatConversation[];
  getRecentConv: () => void;
  deleteConv: (id: number) => void;
}

const useChatConversations: () => UseChatConversations = () => {
  const { fetcher } = useContext(DataContext);
  const { convID, clearConv } = useContext(AIChatContext);
  const [conversations, setConversations] = useState<ChatConversation[]>([]);

  const getRecentConv = useCallback(async () => {
    const res = await fetcher("/chat/conversations");
    if (res?.data?.[0]?.length > 0) {
      setConversations(res.data[0]);
    }
  }, [conversations]);

  const deleteConv = useCallback(
    async (id: number) => {
      if (!id) return;
      const res = await fetcher("/chat/conversation/" + id, {
        method: "DELETE",
      });
      console.log(res?.data);
      if (res?.data === "deleted") {
        if (id === convID) {
          clearConv();
        }
        const newConvs = conversations.filter((conv) => conv.id !== id);
        setConversations(newConvs);
      }
    },
    [convID, clearConv, conversations]
  );

  return {
    conversations,
    getRecentConv,
    deleteConv,
  };
};

export default useChatConversations;
