import { useEffect, useMemo, useState } from "react";
import { ReactComponent as CirclePlayIcon } from "../../../SVGs/circle-play-solid.svg";
import { AIMessage } from "../../../Types/chat";

interface AudioChatCardProps {
  msg: AIMessage;
}

const AudioChatCard: React.FC<AudioChatCardProps> = ({ msg }) => {
  const [audioURL, setAudioURL] = useState<string | undefined>();

  useEffect(() => {
    let url = "";
    if (msg.type === "audio" && msg.content instanceof Blob) {
      url = URL.createObjectURL(msg.content);
      setAudioURL(url);
    }
    return () => {
      if (url) URL.revokeObjectURL(url);
    };
  }, []);

  return (
    <>
      {/* <div onClick={playAudio}>
        <CirclePlayIcon className="w-20px h-20px" />
      </div> */}
      {audioURL && (
        <audio className="w-100" controls>
          <source src={audioURL} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </>
  );
};

export default AudioChatCard;
