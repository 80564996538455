import { initRTEditor, initRTEditorProps } from "./RTEditor";
import { cleanupRTMouse, initRTMouse } from "./RTMouse";
import { initRTRenderer } from "./RTRenderer";
import { cleanupRTTouch, initRTTouch } from "./RTTouch";
import { defaultRTRenderer } from "./types/renderer";
import {
  defaulRTMouse,
  defaultRTCamera,
  defaultRTListeners,
  defaultRTOptions,
  defaultRTTouch,
  RenderTool,
} from "./types/types";

const iRenderTool = (rt: RenderTool) => {
  // rt.camera.zoom = rt.camera.zoom * rt.options.dpi;
  // rt.camera.x = rt.camera.x - rt.canvas.width / 2;
  // rt.camera.y = rt.camera.y - rt.canvas.height / 2;
  // rt.camera.x = rt.options.dpi > 1 ? rt.canvas.width * rt.options.dpi : 0;
  // rt.camera.y = rt.options.dpi > 1 ? rt.canvas.height * rt.options.dpi : 0;
  const onResize = () => {
    rt.canvas.width = rt.canvas.clientWidth * rt.options.dpi;
    rt.canvas.height = rt.canvas.clientHeight * rt.options.dpi;

    // Reset transformations and scale the context
    // rt.ctx.setTransform(rt.options.dpi, 0, 0, rt.options.dpi, 0, 0);
    rt.r.render();
  };
  onResize();
  rt.listeners.resize = onResize;
  window.addEventListener("resize", rt.listeners.resize);
};

const iRenderCleanup = (rt: RenderTool) => {
  window.removeEventListener("resize", rt.listeners.resize);
};

export const initRenderTool = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get canvas 2d context");
  }

  let renderTool: RenderTool = {
    canvas,
    ctx,
    mouse: defaulRTMouse,
    touch: defaultRTTouch,
    camera: defaultRTCamera,
    r: defaultRTRenderer,
    editor: initRTEditorProps(),
    listeners: defaultRTListeners,
    options: defaultRTOptions,
  };

  iRenderTool(renderTool);
  renderTool.listeners.resize();
  initRTRenderer(renderTool);
  initRTEditor(renderTool);
  initRTMouse(renderTool);
  initRTTouch(renderTool);

  renderTool.editor.render();

  return renderTool;
};

export const cleanUpRenderTool = (rt: RenderTool) => {
  cleanupRTMouse(rt);
  iRenderCleanup(rt);
  cleanupRTTouch(rt);
};

export default initRenderTool;
